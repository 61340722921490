import React, { useState, useEffect } from "react";
import {
  Card,
  //   IconButton,
  LinearProgress,
  Collapse,
} from "@mui/material";
import "./card.css";
import { styled } from "@mui/material/styles";
import { ErrorToast, SuccessToast } from "../toast/Toast";

import ConnectWallet from "../Modal/ConnectWallet/ConnectWallet";

import useGetTotalPurchase from "../../hooks/Contract/getToTalPurchase";
import useWeb3 from "../../hooks/Web3Connection/useWeb3";
import { useWeb3React } from "@web3-react/core";
import useGetUsdtBalance from "../../hooks/Contract/getUSDTbalance";
import useMint from "../../hooks/Contract/useMint";
import ScreenLoader from "../loader/ScreenLoader";
import { contractAddress } from "../../utils/Contracts/ContractAddress";
import useGetTotalEvox from "../../hooks/Contract/getTotalEvox";
import useClaim from "../../hooks/Contract/useClaim";
import useMintWithBNB from "../../hooks/Contract/useMintWithBNB";
import useGetBNB from "../../hooks/Contract/getBNBPRice";
import useGetTotalTokens from "../../hooks/Contract/getTotalTokens";

// ExpandMore styled component with color adjustment
// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
//   color: "blue", // Change this to any color you prefer
// }));

const chainImages = {
  ETH: "/Images/Home/eth.svg",
  BNB: "/Images/Home/bnb.svg",
  USDT: "/Images/Home/usdt.svg",
};

const Cardsm = () => {
  const [expanded, setExpanded] = useState(false);
  const [selectedChain, setSelectedChain] = useState("BNB");
  const [walletConnected, setWalletConnected] = useState(false);
  const [progress, setProgress] = useState(50); // Example progress value
  const [time, setTime] = useState(120); // Example time value

  const [showWallet, setShowWallet] = useState(false);
  const [tokenPrice, setTokenPrice] = useState();
  const [usdtPrice, setUsdtPrice] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [usdtConvertedValue, setUsdtConvertedValue] = useState();
  const [mintLoader, setMintLoader] = useState(false);
  const [evoxValue, setEvoxValue] = useState();
  const [show, setShow] = useState(false);
  const [totalPurchase, setTotalPurchase] = useState();

  const [transcation, setatranscation] = useState();
  const { account, chainId } = useWeb3React();

  const { getPurchase } = useGetTotalPurchase({ account });
  const { getEvox } = useGetTotalEvox({ account });

  const { getBalnace } = useGetUsdtBalance({ account });
  const { mintHook, statusState } = useMint({ account });
  const { claimHook } = useClaim({ account });
  const { mintHookBNB } = useMintWithBNB({ account });
  const { getTotalTokensSale } = useGetTotalTokens({ account });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose2 = () => setOpen(false);
  const handleShow = () => setShow(true);
  const [userBNB, setUserBNB] = useState();
  const [bnbPriceSave, setBnbProiceSave] = useState();
  const { getbnb } = useGetBNB({ account });
  const [totalSold, setTotalSold] = useState();
  const [totalNoOfTokens, setTotalNoOfTokens] = useState();
  const [tokenSold, setTokenSold] = useState();
  const [tokenSoldPercentage, setTokenSoldPercentage] = useState();

  const [timeRemaining, setTimeRemaining] = useState(0); // timeRemaining will store seconds remaining

  // Convert remaining time in seconds to days, hours, minutes, and seconds
  const days = Math.floor(timeRemaining / (24 * 60 * 60));
  const hours = Math.floor((timeRemaining % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((timeRemaining % (60 * 60)) / 60);
  const seconds = timeRemaining % 60;

  const customweb3 = useWeb3();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleButtonClick = (chain) => {
    setSelectedChain(chain);
  };

  const handleConnectWallet = () => {
    handleOpen();
  };
  const bnbPrice = async () => {
    try {
      const details = await getbnb(customweb3);
      const balanceInBNB = customweb3.utils.fromWei(details, "ether"); // Convert Wei to BNB
      console.log(balanceInBNB, "this is the details");
      setBnbProiceSave(balanceInBNB);
    } catch (e) {}
  };
  React.useEffect(() => {
    const timerOne = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timerOne);
    };
  }, []);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time + 1;
      });
    }, 1000);
  }, []);
  const addresses = [
    "0x9f....6a83",
    "0x1a....7b29",
    "0x4b....2f18",
    "0x5c....3d47",
    "0x7d....9a56",
    "0x8e....8b35",
  ];

  const puschse = async () => {
    try {
      const balance = await getPurchase(account, customweb3);

      // console.log(
      //   "this is the hash od usdtß",
      //   parseInt(balance?.tokenPrice) / 100000000000000000
      // );

      const valueAllownace = customweb3.utils.fromWei(
        balance?.tokenPrice,
        "ether"
      );

      setTokenPrice(valueAllownace);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMint = async () => {
    try {
      // For USDT Transactions
      if (selectedChain === "USDT") {
        console.log("Enter selected value:", selectedValue);

        if (!selectedValue || selectedValue <= 0) {
          ErrorToast("Please enter a valid value");
          return;
        }

        setMintLoader(true);

        const hash = await mintHook(
          account,
          customweb3,
          evoxValue,
          selectedValue
        );
        SuccessToast("Token purchased successfully");

        setMintLoader(false);
      }
      // For BNB Transactions
      else if (selectedChain === "BNB") {
        if (!evoxValue || evoxValue <= 0) {
          ErrorToast("Please enter a valid value for BNB");
          return;
        }
        console.log(evoxValue, bnbPriceSave, selectedValue, userBNB);
        if (userBNB < selectedValue) {
          ErrorToast("Donot Have BNB in your wallet");
          return;
        }

        setMintLoader(true);

        // Call the mintHook for BNB transactions
        const bnbHash = await mintHookBNB(
          account,
          customweb3,
          evoxValue,
          selectedValue
        );
        SuccessToast("Token purchased successfully with BNB");

        setMintLoader(false);
      }
      // Error if neither USDT nor BNB is selected
      else {
        ErrorToast(
          "Please select a valid chain (USDT or BNB) for the transaction"
        );
      }
    } catch (e) {
      console.log(e);
      setMintLoader(false);
      ErrorToast(e.message);
      // Optionally handle specific errors and display messages
      // let extractedMessage = e.toString().split(":");
      // ErrorToast(extractedMessage[1] + extractedMessage[2].split("\n")[0]);
    }
  };

  const handleUsdtToEvoxChange = async (e) => {
    try {
      const usdtValue = e.target.value;
      setSelectedValue(usdtValue);

      const evoxValue = usdtValue / tokenPrice; // Convert USDT to EVOX
      setEvoxValue(evoxValue); // Set EVOX value
    } catch (error) {
      console.log(error);
    }
  };

  const handleEvoxToUsdtChange = async (e) => {
    try {
      const evoxValue = e.target.value;
      setEvoxValue(evoxValue); // Set EVOX value

      const usdtValue = evoxValue * tokenPrice; // Convert EVOX to USDT
      setSelectedValue(usdtValue); // Set USDT value
    } catch (error) {
      console.log(error);
    }
  };

  const youPurchase = async () => {
    try {
      let totalamount = 0;
      for (let i = 0; i < 4; i++) {
        const details = await getEvox(account, i, customweb3);

        const valueAllownace = customweb3.utils.fromWei(
          details?.amount,
          "ether"
        );
        totalamount += parseInt(valueAllownace);
      }

      setTotalPurchase(totalamount);
      console.log("purcahse amount", totalamount);
    } catch (error) {}
  };

  function truncateAddress(address) {
    if (!address) return "";
    return `${address.slice(0, 12)}......${address.slice(-8)}`;
  }

  async function getBalance() {
    const balanceInWei = await customweb3.eth.getBalance(account);
    const balanceInBNB = customweb3.utils.fromWei(balanceInWei, "ether"); // Convert Wei to BNB
    setUserBNB(balanceInBNB);
    console.log(balanceInBNB, "bnb balnace");
  }
  const UsdtToken = async () => {
    try {
      const balance = await getBalnace(account, customweb3);
      console.log(balance);

      setUsdtPrice(parseInt(balance) / 100000000000000000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClaim = async () => {
    try {
      setMintLoader(true);

      const hash = await claimHook(account, customweb3);
      console.log("this is the has hash", hash);
      // SuccessToast("Token Buy Successfully");
      handleShow();

      setMintLoader(false);
    } catch (e) {
      console.log(e);
      ErrorToast(e.message);
      setMintLoader(false);
    }
  };

  useEffect(() => {
    const fetchContractTransactions = async () => {
      try {
        // Get the wallet address from the web3 instanc

        // Get the current block number
        const latestBlock = await customweb3.eth.getBlockNumber();

        // Define the start block (you can set this to the block where the contract was deployed)
        const startBlock = 0;

        // Create a filter for the contract address to get the logs
        const logs = await customweb3.eth.getPastLogs({
          address: contractAddress,
          fromBlock: startBlock,
          toBlock: latestBlock,
        });

        // Map logs to extract transaction hashes
        const transactionHashes = logs.map((log) => log.transactionHash);

        // Fetch details for each transaction and filter by wallet address
        const filteredTransactions = [];
        for (const hash of transactionHashes) {
          const transaction = await customweb3.eth.getTransaction(hash);

          // Check if the wallet address is involved in the transaction
          if (transaction.from === account.toLowerCase()) {
            filteredTransactions.push(transaction);
          }
        }

        console.log(filteredTransactions);

        setatranscation(filteredTransactions);

        // Store the filtered transactions in state
        // setTransactions(filteredTransactions);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
    youPurchase();
    fetchContractTransactions();
  }, [account, mintLoader]);

  useEffect(() => {
    const startDate = new Date("2024-08-17T00:00:00"); // Set the starting date of the 45-day countdown
    const countdownDuration = 45 * 24 * 60 * 60; // 45 days in seconds

    // Calculate the time difference from the start date to the current time in seconds
    const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
    const startTime = Math.floor(startDate.getTime() / 1000); // start time in seconds
    const timeElapsed = currentTime - startTime;

    // Calculate the remaining time in seconds
    const remainingTime = Math.max(countdownDuration - timeElapsed, 0);

    setTimeRemaining(remainingTime); // Set the initial remaining time

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval when the component unmounts
  }, []);

  const getTotalTokenThisRound = async () => {
    try {
      const data = await getTotalTokensSale(0, customweb3);
      console.log("total purchase data", data);

      // Convert values from Wei to Ether
      const maxBuyAmount = customweb3.utils.fromWei(
        data?.maxBuyAmount,
        "ether"
      );
      const tokensSold = customweb3.utils.fromWei(data?.tokensSold, "ether");

      console.log("Max Buy Amount:", maxBuyAmount);
      console.log("Tokens Sold:", tokensSold);

      setTotalSold(parseInt(maxBuyAmount) - parseInt(tokenSold));

      // Calculate the percentage of tokens sold
      const percentageSold =
        (parseInt(tokensSold) / parseInt(maxBuyAmount)) * 100;

      console.log(
        "Percentage of Tokens Sold:",maxBuyAmount,
        Number(percentageSold).toFixed(90)
      );

      // Set state for display
      setTotalNoOfTokens(maxBuyAmount);
      setTokenSold(tokensSold);
      setTokenSoldPercentage(Number(percentageSold).toFixed(2)); // Converts to a fixed-point notation with 2 decimal places
    } catch (error) {
      console.error("Error fetching token data:", error);
    }
  };

  function formatNumber(number) {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(1) + "T";
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + "B";
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "M";
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + "K";
    } else {
      return number.toString();
    }
  }

  useEffect(() => {
    puschse();
    getBalance();
    UsdtToken();
    bnbPrice();

    getTotalTokenThisRound();
  }, [account]);

  return (
    <div
      style={{
        width: expanded ? "300px" : "276.87px",
        position: "relative",
        zIndex: "9",
        transition: "width 0.3s ease",
      }}
      className="card-resp"
    >
      {mintLoader && <ScreenLoader />}
      <ConnectWallet showWallet={open} onHide={handleClose2} />
      <Card
        sx={{
          background: "linear-gradient(90.82deg, #333333 0%, #474747 100%)",
          borderRadius: "18px",
          boxShadow: "none",
          display: "flex",
          // gap:"50px"
        }}
      >
        <div
          className="card__main-body"
          style={{
            transform: expanded ? "translateX(-100%)" : "translateX(0)",
            transition: "transform 0.5s ease",
          }}
        >
          {/* {!expanded && ( */}
          <div className="card__main-body-1">
            <p className="updateCard__txt">Early Round</p>
            <p className="card__main-body-top">
              BUY IN BEFORE PRICE INCREASES!
            </p>
            <div className="card__main-body-timerTop">
              <div className="card__main-body-timer">
                <h2>{`${days}`.padStart(2, "0")}</h2>
                <p>DAYS</p>
              </div>
              <div className="card__main-body-timer">
                <h2>{`${hours}`.padStart(2, "0")}</h2>
                <p>HRS</p>
              </div>
              <div className="card__main-body-timer">
                <h2>{`${minutes}`.padStart(2, "0")}</h2>
                <p>MIN</p>
              </div>
              <div className="card__main-body-timer">
                <h2>{`${seconds}`.padStart(2, "0")}</h2>
                <p>SEC</p>
              </div>
            </div>
            <div className="card__body-progressbar">
              <div className="card__body-progressbar-txt">
                <p>$0</p>
                {/* <p>$1.025.000</p> */}
                <p>${formatNumber(parseInt(totalNoOfTokens)*tokenPrice)}</p>
              </div>
              <div>
                <LinearProgress
                  variant="determinate"
                  value={tokenSoldPercentage}
                  sx={{
                    height: 6, // Adjust the height if needed
                    background: "#4A4848",
                    borderRadius: "50px",
                    "& .MuiLinearProgress-bar": {
                      background:
                        " linear-gradient(259.99deg, #335BA5 -11%, #53C2DA 68.34%, #5FC7D3 119.07%)",
                      borderRadius: "50px",
                    },
                  }}
                />
              </div>
            </div>
            <div className="card__body-amt">
              <p>YOUR PURCHASED EVOX= {account ? totalPurchase : 0}</p>
              <p>YOUR CLAIMABLE EVOX= 0</p>
            </div>
            <div className="card__body-price">
              <p className="card__body-price-border"></p>
              <p>1 EVOX = ${tokenPrice}</p>
              <p className="card__body-price-border"></p>
            </div>
            <div className="card__body-btn">
              <p>Select Currency (BEP20)</p>
              <div className="card__body-btns">
                {/* <button
                  onClick={() => handleButtonClick("ETH")}
                  style={{
                    backgroundColor:
                      selectedChain === "ETH" ? "white" : "transparent",
                    color: selectedChain === "ETH" ? "#323232" : "#F4F4F4",
                  }}
                >
                  <img src="/Images/Home/eth.svg" alt="" /> ETH
                </button> */}
                <button
                  onClick={() => handleButtonClick("BNB")}
                  style={{
                    backgroundColor:
                      selectedChain === "BNB" ? "white" : "transparent",
                    color: selectedChain === "BNB" ? "#323232" : "#F4F4F4",
                  }}
                >
                  <img src="/Images/Home/bnb.svg" alt="" /> BNB
                </button>
                <button
                  onClick={() => handleButtonClick("USDT")}
                  style={{
                    backgroundColor:
                      selectedChain === "USDT" ? "white" : "transparent",
                    color: selectedChain === "USDT" ? "#323232" : "#F4F4F4",
                  }}
                >
                  <img src="/Images/Home/usdt.svg" alt="" /> USDT
                </button>
              </div>
            </div>
            <div className="card__body-box">
              <div className="card__body-box-container">
                <div className="card__body-box-txt">
                  <span>Pay with {selectedChain}</span>
                  <div style={{ display: "flex", gap: "4px" }}>
                  {
                        account && 
                      <span>
                        {selectedChain == "USDT"
                          ? parseFloat(usdtPrice)?.toFixed(3)
                          : parseFloat(userBNB)?.toFixed(3)}
                      </span>
                      }
                    <p style={{ cursor: "pointer" }}>MAX</p>
                  </div>
                </div>
                <div className="card__body-box-input">
                  <input
                    style={{
                      paddingRight: "27px",
                    }}
                    onChange={handleUsdtToEvoxChange}
                    value={selectedValue}
                    type="number"
                    placeholder="0.00"
                  />
                  <img src={chainImages[selectedChain]} alt={selectedChain} />
                </div>
              </div>
              <div className="card__body-box-container">
                <div className="card__body-box-txt">
                  <span>EVOX Recieved</span>
                </div>
                <div className="card__body-box-input">
                  <input
                    style={{
                      paddingRight: "27px",
                    }}
                    onChange={handleEvoxToUsdtChange} // Change for EVOX to USDT conversion
                    value={evoxValue}
                    type="number"
                    placeholder="0.00"
                  />
                  <img src="/Images/Home/logosm.svg" alt="/" />
                </div>
              </div>
            </div>
            <div className="card__main-body-btns">
              {!account ? (
                <button
                  className="card__main-body-btn"
                  onClick={handleConnectWallet}
                >
                  CONNECT WALLET
                </button>
              ) : (
                <>
                  <button onClick={handleMint} className="card__main-body-btn1">
                    BUY EVOX
                  </button>
                  <button
                    disabled
                    className="card__main-body-btn2"
                    onClick={handleClaim}
                  >
                    CLAIM
                  </button>
                </>
              )}
            </div>
          </div>
          {/* )} */}
          <Collapse
            in={expanded}
            // timeout="auto"
            unmountOnExit
            sx={{
              height: "100%",
              width: "400px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              className="card__main-right"
              style={{
                transform: expanded ? "translateX(100%)" : "translateX(100%)",
                transition: "transform 0.5s ease",
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "100%",
                zIndex: expanded ? 1 : -1, // Ensure proper layering
              }}
            >
              <p>TRANSACTION HISTORY</p>
              {transcation?.map((address, index) => (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      `https://sepolia.etherscan.io/tx/${address?.hash}`
                    );
                  }}
                  className="card__main-right-box"
                  key={index}
                >
                  <p>{truncateAddress(address?.hash)}</p>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
        {expanded ? (
          <img
            onClick={handleExpandClick}
            aria-label="show more"
            expand={expanded}
            src="/Images/Home/arrow-left.svg"
            alt="arrow"
            className="card__img"
          />
        ) : (
          <img
            onClick={handleExpandClick}
            aria-label="show more"
            expand={expanded}
            src="/Images/Home/arrow-right.svg"
            alt="arrow"
            className="card__img"
          />
        )}
      </Card>
    </div>
  );
};

export default Cardsm;
